import React, { FC, useEffect, useState } from 'react';
import { AuthConsumer } from '../AuthProvider';
import JDELogo from '../../../assets/JDElogo.png';
import Footer from '../../../assets/footer.jpg';
import 'material-design-icons/iconfont/material-icons.css';
import './Login.scss';
import { Textfield } from '../../_common/Textfield/Textfield';
import { GetUsersCheckEmail } from '../../../types/schemaTypes';
import { GET_USERS_CHECK_EMAIL } from '../../../graphql/client/queries';
import { useQuery } from 'react-apollo';

const Login: FC = (): JSX.Element => {
    const [email, setEmail] = useState('');
    const [searchEmail, setSearchEmail] = useState('');
    const [isEmailExists, setIsEmailExists] = useState(true);
    const [isEmailCheckCompleted, setIsEmailCheckCompleted] = useState(false);

    useQuery<GetUsersCheckEmail>(GET_USERS_CHECK_EMAIL, {
        variables: { input: { emailAddress: searchEmail } },
        fetchPolicy: 'no-cache',
        onCompleted(data) {
            if (email !== '') {
                setIsEmailCheckCompleted(true);
            } else {
                setIsEmailCheckCompleted(false);
            }

            const emailExists =
                data!.getUsersCheckEmail!.emailExists == null ? false : data!.getUsersCheckEmail!.emailExists;
            setIsEmailExists(emailExists);
        },
    });

    const onsubmitLogin = (userManager, e) => {
        e.preventDefault();
        if (email !== '' && isEmailCheckCompleted && isEmailExists) {
            userManager.signinRedirect({
                /* eslint-disable camelcase */
                login_hint: email,
                prompt: 'login',
            });
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setIsEmailCheckCompleted(false);
            setSearchEmail(email);
        }, 300);
        return () => clearTimeout(delayDebounceFn);
    }, [email]);

    return (
        <AuthConsumer>
            {({ userManager }): JSX.Element => {
                return (
                    <div className="loginsection d-flex h-100">
                        <div className="row justify-content-center align-self-center">
                            <div className="col-md-12 mx-auto text-center">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <img className="" src={JDELogo} alt="JDE Customer Portal" />
                                    </div>
                                </div>
                                <form
                                    className="Login-Form"
                                    onSubmit={(event): void => onsubmitLogin(userManager, event)}
                                >
                                    <input
                                        autoFocus
                                        className="Login-Form-Email"
                                        type="email"
                                        placeholder="EMAIL"
                                        required
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />

                                    <div className="email-not-exist-div">
                                        {email != '' && isEmailCheckCompleted && !isEmailExists && (
                                            <label className="email-not-exist-lable">
                                                User account does not exist. Please contact customer service for
                                                assistance.
                                            </label>
                                        )}
                                    </div>

                                    <button
                                        type="submit"
                                        className="Login-Form-Button"
                                        disabled={email === '' && isEmailCheckCompleted && !isEmailExists}
                                    >
                                        Login
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                );
            }}
        </AuthConsumer>
    );
};

export default Login;
