import gql from 'graphql-tag';

export default gql`
    query GetInvoiceList($input: GetAllInvoiceInput) {
        getInvoiceList(input: $input) {
            invoiceNo
            parentCustomer
            documentCompany
            invoiceDate
            netDueDate
            grossAmount
            openAmount
            invoiceType
            poNumber
            invoiceStatus
            taxAmount
            salesDocumentNumber
            salesDocumentType
            totalRecordsCount
        }
    }
`;
