import React from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import SearchBar from '../../SearchBar/SearchBar';
import './ContactHeader.scss';

const ContactHeader = (): JSX.Element => {
    return (
        <div className="C-Header-Container">
            <div className="C-Header-SearchBar">
                <div className="row">
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-3 my-auto">
                                <label htmlFor="staticEmail">Contact</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Header-ListHeader"></div>
        </div>
    );
};

export default ContactHeader;
