import React, { useEffect, useRef } from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import SearchBar from '../../SearchBar/SearchBar';
import { SearchTypeEnum } from '../../../types/SearchTypeEnum';
import { useAuthContext } from '../../Auth/AuthProvider';
import { PermissionEnum } from '../../../types/PermissionEnum';
import {
    GetAllCustomersList_getAllCustomersList as CustomerUser,
    GetAllCustomersList,
} from '../../../types/schemaTypes';

import { GET_ALL_CUSTOMERSLIST } from '../../../graphql/client/queries';
import { Query, QueryResult, useLazyQuery, useQuery } from 'react-apollo';
import { Error, Loading } from '../../_common';
import { useStoreContext } from '../../App/StoreProvider';
import * as InvoiceActions from '../../../store/actions/invoice';
import * as BOLActions from '../../../store/actions/bol';
import * as PurchaseOrderActions from '../../../store/actions/purchaseOrder';
import * as CustomerListActions from '../../../store/actions/customerList';
import toaster from 'toasted-notes';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router';

const toasterOptions = { duration: 2500 };

const CustomerDropDown = (): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const authContext = useAuthContext();
    const {
        auth: { user },
        customerList: { customerList, customer },
    } = state;
    const opsCustomer = authContext.userHasPermission(PermissionEnum.Customer);
    let selectedCustomer = 0;
    const userProfile = user.profile;
    if (opsCustomer && customer === 0 && userProfile && userProfile.role === 'Customer') {
        console.log('CustomerDropDown - opsCustomer', opsCustomer, user);
        const userProfile = user.profile;
        const customerId = userProfile.Customer;
        selectedCustomer = Number(customerId);
        dispatch(CustomerListActions.setCustomerID(selectedCustomer));
    }

    const onSelectCustomer = (e): any => {
        console.log('onSelectCustomer', e);
        dispatch(CustomerListActions.setCustomerID(Number(e.target.value)));
    };

    const input = {
        search: '',
        customerId: opsCustomer ? selectedCustomer : 0,
    };

    const history = useHistory();
    const navigateToMaintainence = () => {
        history.push(`/maintenance`);
    };

    const renderToast = (message, type = 'info') => {
        interface ToastProps {
            onClose?: () => void;
        }
        const Comp = (props: ToastProps) => {
            return (
                <Alert variant={type} onClose={props.onClose}>
                    {message}
                </Alert>
            );
        };

        Comp.displayName = 'toast';
        return Comp;
    };

    const { data, loading, error } = useQuery(GET_ALL_CUSTOMERSLIST, {
        variables: { input },
        skip:
            customerList.length > 0 ||
            (opsCustomer && customer === 0 && userProfile && userProfile.role === 'Customer'),
    });

    if (customerList.length === 0 && data && data.getAllCustomerList && data.getAllCustomerList.length !== 0) {
        const customerusersData = data.getAllCustomerList;
        dispatch(CustomerListActions.setCustomerList(customerusersData));
    }

    if (error) {
        console.log('erroe', error);
        return (
            <div>
                <Error error={error} />
                <select
                    className="form-control"
                    onChange={e => onSelectCustomer(e)}
                    disabled={opsCustomer && userProfile && userProfile.role === 'Customer'}
                    value={customer}
                >
                    <option value={0}>-- Select Customer --</option>
                    {customerList.map((customer: any, index) => (
                        <option key={customer.id?.toString()} value={customer.id?.toString()}>
                            {customer.customerName}
                        </option>
                    ))}
                </select>
            </div>
        );
    } else {
        if (data && data.getAllCustomerList && data.getAllCustomerList.length !== 0) {
            return (
                <select
                    className="form-control"
                    onChange={e => onSelectCustomer(e)}
                    disabled={opsCustomer && userProfile && userProfile.role === 'Customer'}
                    value={customer}
                >
                    <option value={0}>-- Select Customer --</option>
                    {data.getAllCustomerList.map((customer, index) => (
                        <option key={customer.id?.toString()} value={customer.id?.toString()}>
                            {customer.customerName}
                        </option>
                    ))}
                </select>
            );
        } else {
            return (
                <select
                    className="form-control"
                    onChange={e => onSelectCustomer(e)}
                    disabled={opsCustomer && userProfile && userProfile.role === 'Customer'}
                    value={customer}
                >
                    <option value={0}>-- Select Customer --</option>
                    {customerList.map((customer: any, index) => (
                        <option key={customer.id?.toString()} value={customer.id?.toString()}>
                            {customer.customerName}
                        </option>
                    ))}
                </select>
            );
        }
    }
};

export default CustomerDropDown;
