import { Reducer } from 'react';
import { Actions } from '../../actions/purchaseOrder';
import { Actions as CustomerAction } from '../../actions/customerList';
import { Action, PurchaseOrderState } from '../../types';
import moment from 'moment';

const currentDate = new Date();
const toDate = new Date(new Date().setDate(currentDate.getDate() + 180));
const fromDate = new Date(new Date().setDate(currentDate.getDate() - 30));
const fromDateStr = moment(fromDate).format('YYYY-MM-DD');
const toDateStr = moment(toDate).format('YYYY-MM-DD');

const initialPurchaseOrderState: PurchaseOrderState = {
    searchPhrase: '',
    customer: 0,
    fromDate: fromDateStr,
    toDate: toDateStr,
    product: '',
    pickupLocation: '',
    poNumber: '',
    orderStatus: '',
    shipTo: '',
};

const setSearchPhrase = (search: string, state: PurchaseOrderState): PurchaseOrderState => {
    console.log('PurchaseOrderState - setSearchPhrase', search);
    return {
        ...state,
        searchPhrase: search,
    };
};

const setCustomer = (customer: number, state: PurchaseOrderState): PurchaseOrderState => {
    console.log('PurchaseOrderState - setSearchPhrase', customer);
    return {
        ...state,
        customer: Number(customer),
        product: '',
    };
};

const setFromDate = (fromDate: string, state: PurchaseOrderState): PurchaseOrderState => {
    console.log('PurchaseOrderState - setFromDate', fromDate);
    return {
        ...state,
        fromDate: fromDate,
    };
};

const setToDate = (toDate: string, state: PurchaseOrderState): PurchaseOrderState => {
    console.log('PurchaseOrderState - setToDate', toDate);
    return {
        ...state,
        toDate: toDate,
    };
};

const setProduct = (product: string, state: PurchaseOrderState): PurchaseOrderState => {
    console.log('PurchaseOrderState - setProduct', product);
    return {
        ...state,
        product: product,
    };
};

const setPickupLocation = (pickupLocation: string, state: PurchaseOrderState): PurchaseOrderState => {
    console.log('PurchaseOrderState - setProduct', pickupLocation);
    return {
        ...state,
        pickupLocation: pickupLocation,
    };
};

const setPO = (poNo: string, state: PurchaseOrderState): PurchaseOrderState => {
    console.log('PurchaseOrderState - setPO', poNo);
    return {
        ...state,
        poNumber: poNo,
    };
};

const applyFilters = (filters: any, state: PurchaseOrderState): PurchaseOrderState => {
    console.log('PurchaseOrderState - applyFilters', filters);
    return {
        ...state,
        poNumber: filters.filter_poNumber,
        fromDate: filters.filter_fromDate,
        toDate: filters.filter_toDate,
        pickupLocation: filters.filter_pickupLocation,
        product: filters.filter_product,
        searchPhrase: filters.filter_searchPhrase,
        orderStatus: filters.filter_Status,
        shipTo: filters.filter_ShipTo,
    };
};

const resetFilters = (state: PurchaseOrderState): PurchaseOrderState => {
    console.log('PurchaseOrderState - resetFilters');
    return {
        ...state,
        searchPhrase: '',
        fromDate: fromDateStr,
        toDate: toDateStr,
        product: '',
        pickupLocation: '',
        poNumber: '',
        orderStatus: '',
        shipTo: '',
    };
};

const purchaseOrderReducer: Reducer<PurchaseOrderState, Action> = (state, action): PurchaseOrderState => {
    console.log('purchaseOrderReducer - purchaseOrderReducer', action);
    let newState = { ...state };

    switch (action.type) {
        case Actions.UPDATE_SEARCH_PHRASE:
            newState = setSearchPhrase(action.payload, state);
            break;
        case Actions.FILTER_CUSTOMER_SELECT:
            newState = setCustomer(action.payload, state);
            break;
        case Actions.FILTER_FROMDATE_SELECT:
            newState = setFromDate(action.payload, state);
            break;
        case Actions.FILTER_TODATE_SELECT:
            newState = setToDate(action.payload, state);
            break;
        case Actions.FILTER_PRODUCT_SELECT:
            newState = setProduct(action.payload, state);
            break;
        case Actions.FILTER_PICKUPLOCATION_SELECT:
            newState = setPickupLocation(action.payload, state);
            break;
        case Actions.FILTER_PO:
            newState = setPO(action.payload, state);
            break;
        case Actions.FILTER_ACTION_APPLYFILTER:
            newState = applyFilters(action.payload, state);
            break;
        case Actions.FILTER_ACTION_RESETFILTER:
            newState = resetFilters(state);
            break;
        case CustomerAction.UPDATE_CUSTOMER_ID:
            newState = resetFilters(state);
            break;
    }

    return newState;
};

export { initialPurchaseOrderState, purchaseOrderReducer };
