import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Users from '../../../Users';
import './Content.css';
import PrivateRoute from '../../../Auth/PrivateRoute';
import { Routes } from '../../../../utils';
import SilentRenew from '../../../Auth/SilentRenew';
import BillsLading from '../../../BillsLading';
import Invoices from '../../../Invoices';
import PurchaseOrders from '../../../PurchaseOrders';
import Reports from '../../../Reports';
import Contact from '../../../Contact';
import Maintenance from '../../../Maintenance';
import OpenOrders from '../../../OpenOrders';
import OpenOrdersDetails from '../../../OpenOrders/OpdenOrdersDetail';

export const Content = (): JSX.Element => {
    return (
        <Switch>
            <PrivateRoute exact path={Routes.OPEN_ORDERS.route} component={OpenOrders} />
            <PrivateRoute exact path={Routes.ORDERS_DETAILS_BYORDERNO.route} component={OpenOrdersDetails} />
            <PrivateRoute exact path={Routes.PURCHASE_ORDERS.route} component={PurchaseOrders} />
            <PrivateRoute exact path={Routes.BILLS_LADING.route} component={BillsLading} />
            <PrivateRoute exact path={Routes.INVOICES.route} component={Invoices} />
            <PrivateRoute exact path={Routes.REPORTS.route} component={Reports} />
            <PrivateRoute exact path={Routes.CONTACT.route} component={Contact} />
            <PrivateRoute exact path={Routes.SYSTEM_ADMIN_USERS.route} component={Users} />
            <PrivateRoute exact path={Routes.MAINTENANCE.route} component={Maintenance} />
            <Route path={Routes.SILENT_RENEW.route} component={SilentRenew} />
        </Switch>
    );
};

export default Content;
