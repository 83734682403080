import React, { FC, useEffect, useState } from 'react';
import { Query, QueryResult } from 'react-apollo';
import './Contact.scss';
import 'material-design-icons/iconfont/material-icons.css';
import { Error } from '../_common';
import ContactHeader from '../Header/ContactHeader';
import { Routes } from '../../utils';

const Contact: FC = (): JSX.Element => {
    document.title = 'Contact';
    return (
        <div className="Contact-Container">
            <ContactHeader />
            <div className="Contact-Body">
                <div id="RContactContainer" className="Contact-List">
                    <div className="ContactList">
                        <div className="Contact-Container">
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th style={{ width: '33%' }}>Industrial & Specialty Products Team</th>
                                                <th style={{ width: '33%' }}>Email</th>
                                                <th style={{ width: '33%' }}>Phone</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Industrials & Specialty Products</td>
                                                <td>
                                                    <a href="mailto:sales@ussilica.com">sales@ussilica.com</a>
                                                </td>
                                                <td>800-345-6170</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="table">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th>Oil & Cas Team</th>
                                                <th>Region Market</th>
                                                <th>Title</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Nathaniel Harris</td>
                                                <td>Permian</td>
                                                <td>Regional Sales Manager</td>
                                                <td>
                                                    <a href="mailto:HarrisN@ussilica.com">HarrisN@ussilica.com</a>
                                                </td>
                                                <td>432-307-4081</td>
                                            </tr>
                                            <tr>
                                                <td>NMatthew Haile</td>
                                                <td>Permian</td>
                                                <td>Regional Sales Manager</td>
                                                <td>
                                                    <a href="mailto:Haile@ussilica.com">Haile@ussilica.com</a>
                                                </td>
                                                <td>432-232-0308</td>
                                            </tr>
                                            <tr>
                                                <td>Sammy Chandler</td>
                                                <td>South, Mid-Continent</td>
                                                <td>Sr. Regional Sales Manager</td>
                                                <td>
                                                    <a href="mailto:chandler@ussilica.com">chandler@ussilica.com</a>
                                                </td>
                                                <td>281.455.8402</td>
                                            </tr>
                                            <tr>
                                                <td>Joe Clark</td>
                                                <td>MorhAnsi</td>
                                                <td>Regional Sales Manager</td>
                                                <td>
                                                    <a href="mailto:Clarkj@ussilica.com">Clarkj@ussilica.com</a>
                                                </td>
                                                <td>724-584-2470</td>
                                            </tr>
                                            <tr>
                                                <td>Jon Evenson</td>
                                                <td>Bakken/Rockies</td>
                                                <td>Regional Sales Manager</td>
                                                <td>
                                                    <a href="mailto:Evenson@ussilica.com">Evenson@ussilica.com</a>
                                                </td>
                                                <td>720-233-1282</td>
                                            </tr>
                                            <tr>
                                                <td>Dave Desareaux</td>
                                                <td>South Texas</td>
                                                <td>Regional Sales Manager</td>
                                                <td>
                                                    <a href="mailto:Decareaux@ussilica.com">Decareaux@ussilica.com</a>
                                                </td>
                                                <td>832-913-9732</td>
                                            </tr>
                                            <tr>
                                                <td>Nathaniel Harris</td>
                                                <td>South Texas</td>
                                                <td>Regional Sales Manager</td>
                                                <td>
                                                    <a href="mailto:Bmsckmany@ussilica.com">Bmsckmany@ussilica.com</a>
                                                </td>
                                                <td>832-329.9011</td>
                                            </tr>
                                            <tr>
                                                <td>Morty Redman</td>
                                                <td>ETX/NLA</td>
                                                <td>Regional Sales Manager</td>
                                                <td>
                                                    <a href="mailto:Morty.Redman@ussilica.comm">
                                                        Morty.Redman@ussilica.com
                                                    </a>
                                                </td>
                                                <td>903-371-1987</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="table">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th style={{ width: '33%' }}>Customer Service Teamm</th>
                                                <th style={{ width: '33%' }}>Email</th>
                                                <th style={{ width: '33%' }}>Phone</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Oil & Gas</td>
                                                <td>
                                                    <a href="mailto:FracSand@ussilica.com">FracSand@ussilica.com</a>
                                                </td>
                                                <td>844-468-7263</td>
                                            </tr>
                                            <tr>
                                                <td>Industrial Specialty Products</td>
                                                <td>
                                                    <a href="mailto:ISPCustomerCare@ussilica.com">
                                                        ISPCustomerCare@ussilica.com
                                                    </a>
                                                </td>
                                                <td>844-468-7263</td>
                                            </tr>
                                            <tr>
                                                <td>A/R Inquiries</td>
                                                <td>
                                                    <a href="mailto:ISPCustomerCare@ussilica.com">
                                                        usscredit@ussilica.com
                                                    </a>
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>Webmaster</td>
                                                <td>
                                                    <a href="mailto:ISPCustomerCare@ussilica.com">
                                                        customerportal@ussilica.com
                                                    </a>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
