import React, { FC, useEffect, useRef, useState } from 'react';
import { Query, QueryResult } from 'react-apollo';
import './Reports.scss';
import 'material-design-icons/iconfont/material-icons.css';
import { Error, Loading } from '../_common';
import ReportsHeader from '../Header/ReportsHeader';
import { Routes } from '../../utils';
import { ReportsFilters } from '../Filter/ReportsFilter';
import { useStoreContext } from '../App/StoreProvider';
import Paper from '@mui/material/Paper';
import {
    SortingState,
    IntegratedSorting,
    SelectionState,
    IntegratedSelection,
    PagingState,
    IntegratedPaging,
    CustomPaging,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    DragDropProvider,
    Table,
    TableHeaderRow,
    TableColumnReordering,
    TableSelection,
    PagingPanel,
    TableColumnResizing,
    ColumnChooser,
    Toolbar,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';
import { alpha, styled } from '@mui/material';

import { GetReport_getReport as Report, GetReport } from '../../types/schemaTypes';
import { GET_ALL_REPORT } from '../../graphql/client/queries';
import moment from 'moment';

const PREFIX = 'Demo';

const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
};
const $grey = '#333';
const StyledTable = styled(Table.Table)(({ theme }) => ({
    [`&.${classes.tableStriped}`]: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: alpha($grey, 0.015),
        },
    },
}));

const TableComponent = props => <StyledTable {...props} className={classes.tableStriped} />;

const columns = [
    { name: 'requestedDate', title: 'Requested Date' },
    { name: 'poNumber', title: 'PO Number' },
    { name: 'pickupLocation', title: 'Pickup Location' },
    { name: 'bolNumber', title: 'BOL Number' },
    { name: 'shipToNumber', title: 'ShipToNumber' },
    { name: 'shipToName', title: 'ShipToName' },
    { name: 'alternateBOLNumber', title: 'Alternate BOL Number' },
    { name: 'invoiceNumber', title: 'Invoice Number' },
    { name: 'vehicleNumber', title: 'Vehicle Number' },
    { name: 'wellName', title: 'Well Name' },
    { name: 'quantityLoaded', title: 'Quantity Loaded' },
    { name: 'transactionUOM', title: 'Transaction UOM' },
    { name: 'grossWeight', title: 'Gross Weight' },
    { name: 'tareWeight', title: 'Tare Weight' },
    { name: 'netWeight', title: 'Net Weight' },
];

const rows = [
    {
        rproduct: 'Min-US-Sil 30',
        rdate: '181.50',
        rtotal: '181.50',
    },
];

const Reports: FC = (): JSX.Element => {
    // TODO: Move local state to jobListReducer
    const [state, dispatch] = useStoreContext();
    const [listHeight, setListHeight] = useState(window.screen.height);
    const abortController = new AbortController();
    const [columnOrder, setColumnOrder] = useState(['rproduct', 'rdate', 'rtotal']);
    let currentOffSet = 0;
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageSizes] = useState([10, 20, 30, 50]);
    const [selection, setSelection] = React.useState<Array<number | string>>([]);
    // const [columns, setColumns] = useState<Array<any>>([]);
    const {
        report: { searchString, fromDate, toDate, product, poNumber, pickupLocation },
        customerList: { customer },
    } = state;
    const currentFilters = useRef({});
    const [sorting, setSorting] = useState([{ columnName: 'requestedDate', direction: 'asc' }]);
    let totalRowsCount = 0;
    const [defaultColumnWidths] = useState([
        { columnName: 'requestedDate', width: 180 },
        { columnName: 'poNumber', width: 180 },
        { columnName: 'pickupLocation', width: 180 },
        { columnName: 'bolNumber', width: 180 },
        { columnName: 'shipToNumber', width: 180 },
        { columnName: 'shipToName', width: 180 },
        { columnName: 'alternateBOLNumber', width: 180 },
        { columnName: 'invoiceNumber', width: 180 },
        { columnName: 'vehicleNumber', width: 180 },
        { columnName: 'wellName', width: 180 },
        { columnName: 'quantityLoaded', width: 180 },
        { columnName: 'transactionUOM', width: 180 },
        { columnName: 'grossWeight', width: 180 },
        { columnName: 'tareWeight', width: 180 },
        { columnName: 'netWeight', width: 180 },
    ]);

    const abortRequest = (): void => {
        abortController.abort();
    };

    const setListContainerHeight = (): void => {
        const filterDiv = document.getElementById('jobsFilterContainer');
        const listDiv = document.getElementById('jobsListContainer');

        let filterHeight = 0;
        // setTimeout in place to avoid animation miscalculations
        setTimeout(() => {
            if (filterDiv) {
                filterHeight = filterDiv.scrollHeight;
            }
            if (listDiv) {
                setListHeight(Math.min(filterHeight));
            }
        }, 100);
    };
    document.title = 'My Reports';

    currentOffSet = currentPage;
    if (
        currentFilters &&
        (currentFilters.current['searchString'] !== searchString ||
            currentFilters.current['fromDate'] !== fromDate ||
            currentFilters.current['toDate'] !== toDate ||
            currentFilters.current['customer'] !== customer ||
            currentFilters.current['product'] !== product ||
            currentFilters.current['poNumber'] !== poNumber ||
            currentFilters.current['pickupLocation'] !== pickupLocation)
    ) {
        currentOffSet = 0;
        // setSelection([]);
    }

    const variables = {
        input: {
            searchString,
            customer,
            fromDate,
            toDate,
            pageSize,
            offSet: currentOffSet,
            sortParam: sorting[0].columnName,
            sortDirection: sorting[0].direction,
            product,
            poNumber,
            pickupLocation,
        },
    };

    currentFilters.current = { searchString, customer, fromDate, toDate, product, poNumber, pickupLocation };

    console.log('variables', variables);

    const onPageNumberChanges = count => {
        setSelection([]);
        setCurrentPage(0);
        setPageSize(count);
        console.log('onPageNumberChanges', count);
    };

    const onPageChanges = count => {
        setSelection([]);
        setCurrentPage(count);
        console.log('onPageChanges', count);
    };

    if (customer && customer !== 0) {
        return (
            <Query query={GET_ALL_REPORT} variables={variables} fetchPolicy="network-only">
                {({
                    error,
                    data,
                    loading,
                    refetch,
                    fetchMore,
                }: QueryResult<GetReport | undefined>): JSX.Element | null => {
                    const report: Report[] = [];

                    console.log('report', data);
                    // let colList: any[] = [];
                    if (data && data.getReport && data.getReport.length !== 0) {
                        data.getReport.forEach((element: Report | null): void => {
                            if (element !== null) {
                                totalRowsCount = Number(element.totalRecordsCount);
                                report.push(element);
                            }
                        });
                        console.log('report', report);
                    }

                    return (
                        <div className="Reports-Container">
                            {error && <Error error={error} />}
                            <ReportsHeader />
                            <div className="Reports-Body row">
                                <ReportsFilters abortRequest={abortRequest} onFilterToggle={setListContainerHeight} />
                                <div id="GridContainer" className="pl-0 col-md-9 Reports-List">
                                    <div className="ReportsList">
                                        <div className="JonItem-Container">
                                            {loading && (
                                                <div className="SpinnerContainer">
                                                    <Loading />
                                                </div>
                                            )}
                                            <Paper>
                                                <Grid rows={report} columns={columns}>
                                                    <SelectionState
                                                        selection={selection}
                                                        onSelectionChange={setSelection}
                                                    />
                                                    <IntegratedSelection />
                                                    <SortingState onSortingChange={setSorting} />
                                                    <IntegratedSorting />
                                                    <DragDropProvider />
                                                    <PagingState
                                                        currentPage={currentPage}
                                                        onCurrentPageChange={onPageChanges}
                                                        pageSize={pageSize}
                                                        onPageSizeChange={onPageNumberChanges}
                                                    />
                                                    <CustomPaging totalCount={totalRowsCount} />
                                                    <Table tableComponent={TableComponent} />
                                                    <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                                    <TableHeaderRow showSortingControls />
                                                    <TableColumnReordering
                                                        order={columnOrder}
                                                        onOrderChange={setColumnOrder}
                                                    />
                                                    <TableSelection showSelectAll />
                                                    <TableColumnVisibility />
                                                    <PagingPanel pageSizes={pageSizes} />
                                                </Grid>
                                            </Paper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    } else {
        return (
            <div className="Reports-Container">
                <ReportsHeader />
                <div className="Reports-Body row">
                    <ReportsFilters abortRequest={abortRequest} onFilterToggle={setListContainerHeight} />
                    <div id="GridContainer" className="pl-0 col-md-9 Reports-List">
                        <div className="ReportsList">
                            <div className="JonItem-Container">
                                <Paper>
                                    <Grid rows={rows} columns={columns}>
                                        <PagingState
                                            currentPage={currentPage}
                                            onCurrentPageChange={setCurrentPage}
                                            pageSize={pageSize}
                                            onPageSizeChange={setPageSize}
                                        />
                                        <SelectionState selection={selection} onSelectionChange={setSelection} />
                                        <IntegratedPaging />
                                        <IntegratedSelection />
                                        <SortingState defaultSorting={[{ columnName: 'ponumber', direction: 'asc' }]} />
                                        <IntegratedSorting />
                                        <DragDropProvider />
                                        <Table tableComponent={TableComponent} />
                                        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                        <TableHeaderRow showSortingControls />
                                        <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
                                        <TableSelection showSelectAll />
                                        <TableColumnVisibility />
                                        <PagingPanel pageSizes={pageSizes} />
                                    </Grid>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="Reports-Container">
            <ReportsHeader />
            <div className="Reports-Body row">
                <ReportsFilters abortRequest={abortRequest} onFilterToggle={setListContainerHeight} />
                <div id="GridContainer" className="pl-0 col-md-9 Reports-List" style={{ height: listHeight }}>
                    <div className="ReportsList">
                        <div className="JonItem-Container">
                            <Paper>
                                <Grid rows={rows} columns={columns}>
                                    <PagingState
                                        currentPage={currentPage}
                                        onCurrentPageChange={setCurrentPage}
                                        pageSize={pageSize}
                                        onPageSizeChange={setPageSize}
                                    />
                                    <SelectionState selection={selection} onSelectionChange={setSelection} />
                                    <IntegratedPaging />
                                    <IntegratedSelection />
                                    <SortingState defaultSorting={[{ columnName: 'ponumber', direction: 'asc' }]} />
                                    <IntegratedSorting />
                                    <DragDropProvider />
                                    <Table tableComponent={TableComponent} />
                                    <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                    <TableHeaderRow showSortingControls />
                                    <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
                                    <TableSelection showSelectAll />
                                    <TableColumnVisibility />
                                    <PagingPanel pageSizes={pageSizes} />
                                </Grid>
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reports;
