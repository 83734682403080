import React from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import SearchBar from '../../SearchBar/SearchBar';
import './InvoicesHeader.scss';
import { SearchTypeEnum } from '../../../types/SearchTypeEnum';
import { useAuthContext } from '../../Auth/AuthProvider';
import { PermissionEnum } from '../../../types/PermissionEnum';
import {
    GetAllCustomersList_getAllCustomersList as CustomerUser,
    GetAllCustomersList,
} from '../../../types/schemaTypes';

import { GET_ALL_CUSTOMERSLIST } from '../../../graphql/client/queries';
import { Query, QueryResult } from 'react-apollo';
import { Error, Loading } from '../../_common';
import { useStoreContext } from '../../App/StoreProvider';
import * as InvoiceActions from '../../../store/actions/invoice';
import * as BOLActions from '../../../store/actions/bol';
import * as PurchaseOrderActions from '../../../store/actions/purchaseOrder';
import CustomerDropDown from '../CustomerDropDown';

export enum SortOrder {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
}

const InvoicesHeader = ({ onDownloadInvoices, onPrintInvoices }: any): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const {
        invoice: { searchString, fromDate, toDate, addressNumber, invoiceStatus, poNumber },
        customerList: { customer },
    } = state;

    const onDownloadExcel = (e): void => {
        console.log('onDownloadExcel');
        console.log(
            'PurchaseOrdersHeader - customer',
            customer,
            'process.env.REACT_APP_API_URL,',
            process.env.REACT_APP_API_URL,
            'process.env.REACT_APP_AUTHORITY_URL',
            process.env.REACT_APP_AUTHORITY_URL,
        );
        const queryparams = `?Customer=${customer}&SearchString=${searchString}&FromDate=${
            fromDate ? fromDate : ''
        }&Todate=${toDate ? toDate : ''}&PageSize=0&poNumber=${poNumber}&invoiceStatus=${invoiceStatus}`;
        window.open(`${process.env.REACT_APP_API_URL}Invoice/ExportInvoice${queryparams}`, '_blank');
    };

    return (
        <div className="I-Header-Container">
            <div className="I-Header-SearchBar">
                <div className="row">
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-3 my-auto">
                                <label htmlFor="staticEmail">Customer</label>
                            </div>
                            <div className="col-md-6">
                                <CustomerDropDown></CustomerDropDown>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 text-right">
                        <button className="Button CustomButton-Plain mx-1" onClick={onDownloadExcel}>
                            Download to Excel
                        </button>
                        <button className="Button CustomButton-Plain mx-1" onClick={onDownloadInvoices}>
                            Download Invoice
                        </button>
                        <button className="Button CustomButton-Plain mx-1" onClick={onPrintInvoices}>
                            Print Invoices
                        </button>
                    </div>
                </div>
            </div>
            <div className="Header-ListHeader"></div>
        </div>
    );
};

export default InvoicesHeader;
