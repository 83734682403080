import React, { FC, useEffect, useRef, useState } from 'react';
import { Query, QueryResult, useQuery, useLazyQuery } from 'react-apollo';
import './BillsLading.scss';
import 'material-design-icons/iconfont/material-icons.css';
import { Error, Loading } from '../_common';
import BillsLadingHeader from '../Header/BillsLadingHeader';
import { Routes } from '../../utils';
import { BillsLadingFilters } from '../Filter/BillsLadingFilter';
import { useStoreContext } from '../App/StoreProvider';

import Paper from '@mui/material/Paper';
import {
    SortingState,
    IntegratedSorting,
    SelectionState,
    IntegratedSelection,
    PagingState,
    IntegratedPaging,
    CustomPaging,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    DragDropProvider,
    Table,
    TableHeaderRow,
    TableColumnReordering,
    TableSelection,
    PagingPanel,
    TableColumnResizing,
    ColumnChooser,
    Toolbar,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';
import { alpha, styled } from '@mui/material';

import { GetBolList_getBolList as Bol, GetBolList } from '../../types/schemaTypes';
import { GET_ALL_BOL_LIST, CHECK_BOL_FILE_EXISTS } from '../../graphql/client/queries';
import * as BOLActions from '../../store/actions/bol';

const PREFIX = 'BL';

const classes = {
    tableStriped: `${PREFIX}-tableStriped`,
};
const $grey = '#333';
const StyledTable = styled(Table.Table)(({ theme }) => ({
    [`&.${classes.tableStriped}`]: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: alpha($grey, 0.015),
        },
    },
}));

const TableComponent = props => <StyledTable {...props} className={classes.tableStriped} />;

const BillsLading: FC = (): JSX.Element => {
    // TODO: Move local state to jobListReducer
    const [state, dispatch] = useStoreContext();
    const {
        bol: {
            searchPhrase,
            fromDate,
            toDate,
            bolNumber,
            pickupLocation,
            region,
            wellName,
            scaleTicket,
            poNo,
            invoiceNo,
        },
        customerList: { customer },
    } = state;
    const [listHeight, setListHeight] = useState(window.screen.height);
    const abortController = new AbortController();
    let currentOffSet = 0;

    const [columns] = useState([
        { name: 'bolNo', title: 'BOL#' },
        { name: 'pickUpLocation', title: 'Pick Up Location' },
        // { name: 'parentName', title: 'Customer' },
        { name: 'quantityShipped', title: 'Quantity Shipped' },
        { name: 'uom', title: 'UoM' },
        { name: 'product', title: 'Product' },
        { name: 'poNo', title: 'PO#' },
        { name: 'shipDate', title: 'Ship Date' },
        { name: 'invoiceNo', title: 'Invoice#' },
        //{ name: 'invoiceType', title: 'Invoice Type' },
        { name: 'vehicleNo', title: 'Rail or Vehicle#' },
        { name: 'wellName', title: 'Well Name' },
        { name: 'scaleTicket', title: 'Scale Ticket#' },
        { name: 'coa', title: 'COA' },
    ]);

    const [columnOrder, setColumnOrder] = useState([
        'bolNo',
        'invoiceNo',
        'pickUpLocation',
        'quantityShipped',
        'uom',
        'product',
        'poNo',
        'shipDate',
        'vehicleNo',
        'wellName',
        'scaleTicket',
        'coa',
    ]);

    const [defaultColumnWidths] = useState([
        { columnName: 'bolNo', width: 100 },
        { columnName: 'pickUpLocation', width: 100 },
        // { columnName: 'parentName', width: 180 },
        { columnName: 'quantityShipped', width: 100 },
        { columnName: 'uom', width: 100 },
        { columnName: 'product', width: 100 },
        { columnName: 'poNo', width: 100 },
        { columnName: 'shipDate', width: 100 },
        { columnName: 'invoiceNo', width: 100 },
        //{ columnName: 'invoiceType', width: 100 },
        { columnName: 'vehicleNo', width: 100 },
        { columnName: 'wellName', width: 100 },
        { columnName: 'scaleTicket', width: 100 },
        { columnName: 'coa', width: 100 },
    ]);

    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageSizes] = useState([10, 20, 30, 50]);
    const currentFilters = useRef({});
    const bolListData = useRef({});
    const [selection, setSelection] = React.useState<Array<number | string>>([]);
    const [sorting, setSorting] = useState([{ columnName: 'shipDate', direction: 'desc' }]);
    const [isLoadData, setIsLoadData] = useState(false);

    const abortRequest = (): void => {
        abortController.abort();
    };

    const setListContainerHeight = (): void => {
        const filterDiv = document.getElementById('jobsFilterContainer');
        const listDiv = document.getElementById('jobsListContainer');

        let filterHeight = 0;
        // setTimeout in place to avoid animation miscalculations
        setTimeout(() => {
            if (filterDiv) {
                filterHeight = filterDiv.scrollHeight;
            }
            if (listDiv) {
                setListHeight(Math.min(filterHeight));
            }
        }, 100);
    };
    document.title = 'Bills of Lading Inquiry';

    currentOffSet = currentPage;
    if (
        currentFilters &&
        (currentFilters.current['searchPhrase'] !== searchPhrase ||
            currentFilters.current['fromDate'] !== fromDate ||
            currentFilters.current['toDate'] !== toDate ||
            currentFilters.current['customer'] !== customer ||
            currentFilters.current['bolNumber'] !== bolNumber ||
            currentFilters.current['pickupLocation'] !== pickupLocation ||
            currentFilters.current['region'] !== region ||
            currentFilters.current['wellName'] !== wellName ||
            currentFilters.current['scaleTicket'] !== scaleTicket ||
            currentFilters.current['poNo'] !== poNo ||
            currentFilters.current['invoiceNo'] !== invoiceNo)
    ) {
        currentOffSet = 0;
        setCurrentPage(0);
        setSelection([]);
    }

    // useEffect(() => {
    //     dispatch(BOLActions.setFilterValue('', BOLActions.Actions.BOL_FILTER_ACTION_RESETFILTER));
    //     setIsLoadData(true);
    // }, []);

    const variables = {
        input: {
            searchString: searchPhrase,
            customer,
            fromDate,
            toDate,
            bolNumber,
            pickupLocation,
            region,
            wellName,
            scaleTicket,
            pageSize,
            offSet: currentOffSet,
            sortParam: sorting[0].columnName,
            sortDirection: sorting[0].direction,
            poNo,
            invoiceNumber: invoiceNo,
        },
    };

    // console.log('BillsLading variables', variables);
    currentFilters.current = {
        searchPhrase,
        customer,
        fromDate,
        toDate,
        bolNumber,
        pickupLocation,
        region,
        wellName,
        scaleTicket,
        poNo,
        invoiceNo,
    };

    // const [checkIsBolFileExistsInBE, { loading, error, data }] = useLazyQuery(CHECK_BOL_FILE_EXISTS);
    // const { data, loading, error } = useQuery(CHECK_BOL_FILE_EXISTS, { variables: { selectedValue } });

    const onDownloadBol = () => {
        console.log('onDownloadBol - selection', selection);
        if (selection.length > 0) {
            let bolNos = '';
            selection.forEach(element => {
                bolNos =
                    bolNos === ''
                        ? bolListData.current[element].bolNo
                        : bolNos + ',' + bolListData.current[element].bolNo;
            });
            console.log('onDownloadBol - bolNos', bolNos);
            console.log(
                'BillsLadingHeader - customer',
                customer,
                'process.env.REACT_APP_API_URL,',
                process.env.REACT_APP_API_URL,
                'process.env.REACT_APP_AUTHORITY_URL',
                process.env.REACT_APP_AUTHORITY_URL,
            );
            const queryparams = `?bolNo=${bolNos}`;
            window.open(`${process.env.REACT_APP_API_URL}BOL/DownloadBol${queryparams}`, '_blank');
        }
    };

    const onPrintBol = () => {
        console.log('onPrintBol - selection', selection);
        console.log('onDownloadBol - selection', selection);
        if (selection.length > 0) {
            let bolNos = '';
            selection.forEach(element => {
                bolNos =
                    bolNos === ''
                        ? bolListData.current[element].bolNo
                        : bolNos + ',' + bolListData.current[element].bolNo;
            });
            console.log('onDownloadBol - bolNos', bolNos);
            console.log(
                'BillsLadingHeader - customer',
                customer,
                'process.env.REACT_APP_API_URL,',
                process.env.REACT_APP_API_URL,
                'process.env.REACT_APP_AUTHORITY_URL',
                process.env.REACT_APP_AUTHORITY_URL,
            );
            const queryparams = `?bolNo=${bolNos}`;
            window.open(`${process.env.REACT_APP_API_URL}BOL/PrintBol${queryparams}`, '_blank');
        }
    };

    const onPageNumberChanges = count => {
        setSelection([]);
        setCurrentPage(0);
        setPageSize(count);
        console.log('onPageNumberChanges', count);
    };

    const onPageChanges = count => {
        setSelection([]);
        setCurrentPage(count);
        console.log('onPageChanges', count);
    };

    if (customer) {
        return (
            <Query query={GET_ALL_BOL_LIST} variables={variables} fetchPolicy="network-only">
                {({
                    error,
                    data,
                    loading,
                    refetch,
                    fetchMore,
                }: QueryResult<GetBolList | undefined>): JSX.Element | null => {
                    const bolList: Bol[] = [];
                    let totalRowsCount = 0;
                    // if (error) {
                    //     return <Error error={error} />;
                    // } else {

                    if (data && data.getBolList && data.getBolList.length !== 0) {
                        // totalRowsCount = data.getBolList[0].totalRecordsCount;
                        data.getBolList.forEach((element: Bol | null): void => {
                            if (element !== null) {
                                totalRowsCount = Number(element.totalRecordsCount);
                                bolList.push(element);
                            }
                        });
                        // console.log('bolList', bolList);
                        bolListData.current = bolList;
                    }
                    // }

                    return (
                        <div className="BillsLading-Container">
                            {error && <Error error={error} />}
                            <BillsLadingHeader onDownloadBols={onDownloadBol} onPrintBols={onPrintBol} />
                            <div id="BodyGrid" className="BillsLading-Body row">
                                <BillsLadingFilters
                                    abortRequest={abortRequest}
                                    onFilterToggle={setListContainerHeight}
                                />
                                <div id="GridContainer" className="pl-0 col-md-9 BillsLading-List">
                                    <div className="BillsLadingList">
                                        <div className="JonItem-Container">
                                            {loading && (
                                                <div className="SpinnerContainer">
                                                    <Loading />
                                                </div>
                                            )}
                                            <Paper>
                                                <Grid rows={bolList} columns={columns}>
                                                    <SelectionState
                                                        selection={selection}
                                                        onSelectionChange={setSelection}
                                                    />
                                                    <IntegratedSelection />
                                                    <SortingState
                                                        defaultSorting={[{ columnName: 'ponumber', direction: 'asc' }]}
                                                    />
                                                    <DragDropProvider />
                                                    <SortingState onSortingChange={setSorting} />
                                                    <PagingState
                                                        currentPage={currentPage}
                                                        onCurrentPageChange={onPageChanges}
                                                        pageSize={pageSize}
                                                        onPageSizeChange={onPageNumberChanges}
                                                    />
                                                    <CustomPaging totalCount={totalRowsCount} />
                                                    <Table tableComponent={TableComponent} />
                                                    <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                                    <TableHeaderRow showSortingControls />
                                                    <TableColumnReordering
                                                        order={columnOrder}
                                                        onOrderChange={setColumnOrder}
                                                    />
                                                    <TableSelection showSelectAll />
                                                    <TableColumnVisibility />
                                                    <PagingPanel pageSizes={pageSizes} />
                                                </Grid>
                                            </Paper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    } else {
        return (
            <div className="BillsLading-Container">
                <BillsLadingHeader onDownloadBols={onDownloadBol} onPrintBols={onPrintBol} />
                <div id="BodyGrid" className="BillsLading-Body row">
                    <BillsLadingFilters abortRequest={abortRequest} onFilterToggle={setListContainerHeight} />
                    <div id="GridContainer" className="pl-0 col-md-9 BillsLading-List">
                        <div className="BillsLadingList">
                            <div className="JonItem-Container">
                                <Paper>
                                    <Grid rows={[]} columns={columns}>
                                        <PagingState
                                            currentPage={currentPage}
                                            onCurrentPageChange={setCurrentPage}
                                            pageSize={pageSize}
                                            onPageSizeChange={setPageSize}
                                        />
                                        <SelectionState selection={selection} onSelectionChange={setSelection} />
                                        <IntegratedPaging />
                                        <IntegratedSelection />
                                        <SortingState defaultSorting={[{ columnName: 'ponumber', direction: 'asc' }]} />
                                        <IntegratedSorting />
                                        <DragDropProvider />
                                        <CustomPaging totalCount={0} />
                                        <Table tableComponent={TableComponent} />
                                        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                        <TableHeaderRow showSortingControls />
                                        <TableColumnReordering order={columnOrder} onOrderChange={setColumnOrder} />
                                        <TableSelection showSelectAll />
                                        <TableColumnVisibility />
                                        <PagingPanel pageSizes={pageSizes} />
                                    </Grid>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default BillsLading;
