import './Filter.scss';

import React, { useEffect, useState } from 'react';
import { Query, QueryResult } from 'react-apollo';
import Button from 'react-bootstrap/Button';
import { Error, Loading } from '../_common';
import { useStoreContext } from '../App/StoreProvider';
import * as PurchaseOrderActions from '../../store/actions/purchaseOrder';

import {
    GetPurchaseOrderProductList_getPurchaseOrderProductList as PurchaseOrderProduct,
    GetPurchaseOrderProductList,
    GetPurchaseOrderPickupLocationList,
    GetPurchaseOrderPickupLocationList_getPurchaseOrderPickupLocationList as PurchaseOrderPickupLocation,
    GetAllPurchaseOrderFilterStatus,
    GetAllPurchaseOrderFilterStatus_getAllPurchaseOrderFilterStatus as POFilterStatus,
    GetAllPurchaseOrderFilterShipTo,
    GetAllPurchaseOrderFilterShipTo_getAllPurchaseOrderFilterShipTo as POFilterShipTo,
} from '../../types/schemaTypes';
import {
    GET_ALL_PURCHASEORDER_PRODUCT_LIST,
    GET_ALL_PURCHASEORDER_PICKUPLOCATION_LIST,
    GET_ALL_PURCHASEORDER_FILTERSTATUS,
    GET_ALL_PURCHASEORDER_FILTERSHIPTO,
} from '../../graphql/client/queries';

interface JobFiltersProps {
    abortRequest: () => void;
    onFilterToggle: () => void;
}

export const PurchaseOrderFilters = ({ abortRequest, onFilterToggle }: JobFiltersProps): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    // const { purchaseOrder: { searchPhrase, fromDate, toDate }, customerList: { customer } } = state;
    const {
        purchaseOrder: { searchPhrase, fromDate, toDate, pickupLocation, poNumber, product, orderStatus, shipTo },
        customerList: { customer },
    } = state;
    const search: React.Ref<HTMLInputElement> = React.createRef();
    const searchPO: React.Ref<HTMLInputElement> = React.createRef();

    const [filter_searchPhrase, setSearchPhrase] = useState(searchPhrase);
    const [filter_pickupLocation, setPickupLocation] = useState(pickupLocation);
    const [filter_poNumber, setPONumber] = useState(poNumber);
    const [filter_fromDate, setFromDate] = useState(fromDate);
    const [filter_toDate, setToDate] = useState(toDate);
    const [filter_product, setProduct] = useState(product);
    const [filter_Status, setStatus] = useState(orderStatus);
    const [filter_ShipTo, setShipTo] = useState(shipTo);
    const [isApplyFilterClicked, setIsApplyFilterClicked] = useState(false);

    console.log(
        'purchaseOrder: { searchPhrase, fromDate, toDate, pickupLocation, poNumber, product }',
        searchPhrase,
        fromDate,
        toDate,
        pickupLocation,
        poNumber,
        product,
    );

    useEffect(() => {
        console.log('useEffect - customer', customer);
        setSearchPhrase('');
        setPONumber('');
        // setFromDate(null);
        // setToDate(null);
        setProduct('');
        setPickupLocation('');
        setStatus('');
    }, [customer]);

    useEffect(() => {
        setPONumber(poNumber);
    }, [poNumber]);

    useEffect(() => {
        setFromDate(fromDate);
    }, [fromDate]);

    useEffect(() => {
        setToDate(toDate);
    }, [toDate]);

    useEffect(() => {
        setProduct(product);
    }, [product]);

    useEffect(() => {
        setPickupLocation(pickupLocation);
    }, [pickupLocation]);

    useEffect(() => {
        setStatus(orderStatus);
    }, [orderStatus]);

    useEffect(() => {
        setShipTo(shipTo);
    }, [shipTo]);

    const updateSearchPhrase = (e: React.KeyboardEvent<HTMLInputElement>): any => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const searchPhrase = search && search.current ? search.current.value : '';
            // setSearchPhrase(searchPhrase);
            // dispatch(PurchaseOrderActions.setSearchPhrase(searchPhrase));
        }
    };

    const updateSearchPO = (e: React.KeyboardEvent<HTMLInputElement>): any => {
        console.log('updateSearchPO', searchPO);
        if (e.key === 'Enter') {
            e.preventDefault();
            const search = searchPO && searchPO.current ? searchPO.current.value : '';
            console.log('updateSearchPO', search);
            // setPONumber(search);
            // dispatch(PurchaseOrderActions.setSearchPO(search));
        }
    };

    const onChange = (e): void => {
        console.log('onChange', e.target.value);
    };

    const onPOChange = (e): void => {
        console.log('filter_searchPhrase', e.target.value);
        setPONumber(e.target.value);
    };

    const onSearchChange = (e): void => {
        console.log('onChange', e.target.value);
        setSearchPhrase(e.target.value);
    };

    const onFromDateChange = (e): void => {
        console.log('onFromDateChange', e.target.value);
        // setIsApplyFilterClicked(false);
        // if (e.target.value !== '') {
        setFromDate(e.target.value);
        // dispatch(PurchaseOrderActions.setFromDate(e.target.value));
        // }
    };

    const onToDateChange = (e): void => {
        console.log('onToDateChange', e.target.value);
        // setIsApplyFilterClicked(false);
        // if (e.target.value !== '') {
        setToDate(e.target.value);
        // dispatch(PurchaseOrderActions.setToDate(e.target.value));
        // }
    };

    const [activeKey, setActiveKey] = useState('0');

    const onSelectAll = (): void => {
        setActiveKey(activeKey === '0' ? '1' : '0');
    };

    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        document.body.classList.toggle('hide-sidebar', isOpen);
    }, [isOpen]);

    const onProductChanges = (e): void => {
        console.log('onProductChanges', e.target.value);
        setProduct(e.target.value);
        // dispatch(PurchaseOrderActions.setProduct(e.target.value));
    };

    const onPickUpLocationChanges = (e): void => {
        console.log('onPickUpLocationChanges', e.target.value);
        setPickupLocation(e.target.value);
        // dispatch(PurchaseOrderActions.setpickupLocation(e.target.value));
    };

    const onStatusChanges = (e): void => {
        console.log('onStatusChanges', e.target.value);
        setStatus(e.target.value);
    };

    const onShipToChange = (e): void => {
        console.log('onShipToChange', e.target.value);
        setShipTo(e.target.value);
    };

    const applyFilters = () => {
        console.log(
            'filter_searchPhrase, filter_pickupLocation, filter_product, filter_fromDate, filter_toDate, filter_poNumber filter_Status',
            filter_searchPhrase,
            filter_pickupLocation,
            filter_product,
            filter_fromDate,
            filter_toDate,
            filter_poNumber,
            filter_Status,
            filter_ShipTo,
        );
        setIsApplyFilterClicked(true);
        if (filter_fromDate && filter_toDate) {
            dispatch(
                PurchaseOrderActions.applyFilters({
                    filter_searchPhrase,
                    filter_pickupLocation,
                    filter_product,
                    filter_fromDate,
                    filter_toDate,
                    filter_poNumber,
                    filter_Status,
                    filter_ShipTo,
                }),
            );
        }
    };

    const resetFilters = () => {
        setIsApplyFilterClicked(false);
        setSearchPhrase('');
        setPONumber('');
        setFromDate(null);
        setToDate(null);
        setProduct('');
        setPickupLocation('');
        setStatus('');
        setShipTo('');
    };

    const variables = {
        input: {
            searchString: searchPhrase,
            customer,
            fromDate,
            toDate,
        },
    };

    return (
        <div id="jobsFilterContainer" className="JobManagement-Filter col-md-3 pr-0">
            <div className="Filter-Container">
                <div>
                    <div className="accordion">
                        <div className="FilterItem-Card card">
                            <div className="CustomToggle-Container">
                                <div className="CustomToggle-Header">
                                    <p className="CustomToggle-Title">Purchase Order Inquiry</p>
                                    <Button
                                        className="Filter-SelectAll"
                                        variant="link"
                                        onClick={() => setIsOpen(!isOpen)}
                                    >
                                        <i className="material-icons">swap_horiz</i>
                                    </Button>
                                </div>
                            </div>
                            <div className="CustomToggle-Divider"></div>
                            <div className="collapse show">
                                <div className="FilterItem-Body FilterItem-BodyPadding card-body">
                                    <div className="SearchBar-Container">
                                        <i className="material-icons SearchBar-Icon">search</i>
                                        <input
                                            id="textSearch"
                                            type="text"
                                            placeholder="Search"
                                            onChange={e => onSearchChange && onSearchChange(e)}
                                            className="SearchBar-Input"
                                            ref={search}
                                            value={filter_searchPhrase}
                                            // value={state.messageList.searchPhrase}
                                            onKeyDown={(e): void => updateSearchPhrase(e)}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="row  pt-3">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Ship To</label>
                                                {customer !== 0 ? (
                                                    <Query
                                                        query={GET_ALL_PURCHASEORDER_FILTERSHIPTO}
                                                        variables={variables}
                                                        fetchPolicy="network-only"
                                                    >
                                                        {({
                                                            error,
                                                            data,
                                                            loading,
                                                            refetch,
                                                            fetchMore,
                                                        }: QueryResult<
                                                            GetAllPurchaseOrderFilterShipTo | undefined
                                                        >): JSX.Element | null => {
                                                            const purchaseOrderFilterShipTo: POFilterShipTo[] = [];
                                                            console.log(
                                                                'data-GET_ALL_PURCHASEORDER_FILTERSHIPTO',
                                                                data,
                                                            );

                                                            if (
                                                                data &&
                                                                data.getPurchaseOrderFilterShipTo &&
                                                                data.getPurchaseOrderFilterShipTo.length !== 0
                                                            ) {
                                                                data.getPurchaseOrderFilterShipTo.forEach(
                                                                    (element: POFilterShipTo | null): void => {
                                                                        if (element !== null) {
                                                                            // totalRowsCount = Number(element.totalRecordsCount);
                                                                            purchaseOrderFilterShipTo.push(element);
                                                                        }
                                                                    },
                                                                );
                                                                console.log(
                                                                    'purchaseOrderFilterShipTo',
                                                                    purchaseOrderFilterShipTo,
                                                                );
                                                            }
                                                            // }
                                                            return (
                                                                <select
                                                                    className="form-control"
                                                                    value={filter_ShipTo}
                                                                    onChange={e => onShipToChange(e)}
                                                                >
                                                                    <option value={''}>All</option>
                                                                    {purchaseOrderFilterShipTo.map((prd, index) => (
                                                                        <option
                                                                            key={prd.shipToId?.toString()}
                                                                            value={prd.shipToId?.toString()}
                                                                        >
                                                                            {prd.shipToId} {prd.shipToName}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            );
                                                        }}
                                                    </Query>
                                                ) : (
                                                    <select className="form-control">
                                                        <option>All</option>
                                                    </select>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>PO Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    ref={searchPO}
                                                    value={filter_poNumber}
                                                    //defaultValue={poNo}
                                                    onChange={e => onPOChange && onPOChange(e)}
                                                    onKeyDown={(e): void => updateSearchPO(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Date From <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input
                                                    type="date"
                                                    className={`form-control ${
                                                        isApplyFilterClicked && !filter_fromDate
                                                            ? 'Filter-Error-Required'
                                                            : ''
                                                    }`}
                                                    required
                                                    value={filter_fromDate ? filter_fromDate : ''}
                                                    onChange={e => onFromDateChange && onFromDateChange(e)}
                                                />
                                                {isApplyFilterClicked && !filter_fromDate && (
                                                    <label style={{ color: 'red' }}>Date from is required</label>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Date To <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input
                                                    type="date"
                                                    className={`form-control ${
                                                        isApplyFilterClicked && !filter_toDate
                                                            ? 'Filter-Error-Required'
                                                            : ''
                                                    }`}
                                                    required
                                                    value={filter_toDate ? filter_toDate : ''}
                                                    onChange={e => onToDateChange && onToDateChange(e)}
                                                />
                                                {isApplyFilterClicked && !filter_toDate && (
                                                    <label style={{ color: 'red' }}>Date to is required</label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Pickup Location</label>
                                                {customer !== 0 ? (
                                                    <Query
                                                        query={GET_ALL_PURCHASEORDER_PICKUPLOCATION_LIST}
                                                        variables={variables}
                                                        fetchPolicy="network-only"
                                                    >
                                                        {({
                                                            error,
                                                            data,
                                                            loading,
                                                            refetch,
                                                            fetchMore,
                                                        }: QueryResult<
                                                            GetPurchaseOrderPickupLocationList | undefined
                                                        >): JSX.Element | null => {
                                                            const purchaseOrderPickupLocationList: PurchaseOrderPickupLocation[] = [];
                                                            console.log(
                                                                'data-GET_ALL_PURCHASEORDER_PICKUPLOCATION_LIST',
                                                                data,
                                                            );
                                                            const totalRowsCount = 0;
                                                            // if (error) {
                                                            //     return <Error error={error} />;
                                                            // } else {

                                                            if (
                                                                data &&
                                                                data.getPurchaseOrderPickupLocation &&
                                                                data.getPurchaseOrderPickupLocation.length !== 0
                                                            ) {
                                                                data.getPurchaseOrderPickupLocation.forEach(
                                                                    (
                                                                        element: PurchaseOrderPickupLocation | null,
                                                                    ): void => {
                                                                        if (element !== null) {
                                                                            // totalRowsCount = Number(element.totalRecordsCount);
                                                                            purchaseOrderPickupLocationList.push(
                                                                                element,
                                                                            );
                                                                        }
                                                                    },
                                                                );
                                                                console.log(
                                                                    'purchaseOrderPickupLocationList',
                                                                    purchaseOrderPickupLocationList,
                                                                );
                                                            }
                                                            // }
                                                            return (
                                                                <select
                                                                    className="form-control"
                                                                    value={filter_pickupLocation}
                                                                    onChange={e => onPickUpLocationChanges(e)}
                                                                >
                                                                    <option value={''}>All</option>
                                                                    {purchaseOrderPickupLocationList.map(
                                                                        (prd, index) => (
                                                                            <option
                                                                                key={prd.pickUpLocationId?.toString()}
                                                                                value={prd.pickUpLocationId?.toString()}
                                                                            >
                                                                                {prd.pickUpLocation}
                                                                            </option>
                                                                        ),
                                                                    )}
                                                                </select>
                                                            );
                                                        }}
                                                    </Query>
                                                ) : (
                                                    <select className="form-control">
                                                        <option>All</option>
                                                    </select>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Product</label>
                                                {customer !== 0 ? (
                                                    <Query
                                                        query={GET_ALL_PURCHASEORDER_PRODUCT_LIST}
                                                        variables={variables}
                                                        fetchPolicy="network-only"
                                                    >
                                                        {({
                                                            error,
                                                            data,
                                                            loading,
                                                            refetch,
                                                            fetchMore,
                                                        }: QueryResult<
                                                            GetPurchaseOrderProductList | undefined
                                                        >): JSX.Element | null => {
                                                            const purchaseOrderProductList: PurchaseOrderProduct[] = [];
                                                            const totalRowsCount = 0;
                                                            // if (error) {
                                                            //     return <Error error={error} />;
                                                            // }
                                                            if (
                                                                data &&
                                                                data.getPurchaseOrderProductList &&
                                                                data.getPurchaseOrderProductList.length !== 0
                                                            ) {
                                                                data.getPurchaseOrderProductList.forEach(
                                                                    (element: PurchaseOrderProduct | null): void => {
                                                                        if (element !== null) {
                                                                            // totalRowsCount = Number(element.totalRecordsCount);
                                                                            purchaseOrderProductList.push(element);
                                                                        }
                                                                    },
                                                                );
                                                                console.log(
                                                                    'purchaseOrderProductList',
                                                                    purchaseOrderProductList,
                                                                );
                                                            }
                                                            return (
                                                                <select
                                                                    className="form-control"
                                                                    value={filter_product}
                                                                    onChange={e => onProductChanges(e)}
                                                                >
                                                                    <option value={''}>All</option>
                                                                    {purchaseOrderProductList.map((prd, index) => (
                                                                        <option
                                                                            key={prd.productNumber?.toString()}
                                                                            value={prd.productNumber?.toString()}
                                                                        >
                                                                            {prd.productName}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            );
                                                        }}
                                                    </Query>
                                                ) : (
                                                    <select className="form-control">
                                                        <option>All</option>
                                                    </select>
                                                )}
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="row pt-3">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <button className="btn_clear" onClick={resetFilters}>
                                                    Clear Filters
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <button className="btn_apply" onClick={applyFilters}>
                                                    Apply Filters
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
