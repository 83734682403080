import { Reducer } from 'react';
import { Actions } from '../../actions/invoice';
import { Action } from '../../types';
import { InvoiceState } from '../../types/invoiceState';
import { Actions as CustomerAction } from '../../actions/customerList';
import moment from 'moment';

const currentDate = new Date();
const toDate = new Date(new Date().setDate(currentDate.getDate() + 180));
const fromDate = new Date(new Date().setDate(currentDate.getDate() - 30));
const fromDateStr = moment(fromDate).format('YYYY-MM-DD');
const toDateStr = moment(toDate).format('YYYY-MM-DD');

const initialInvoiceState: InvoiceState = {
    searchString: '',
    customer: 0,
    fromDate: fromDateStr,
    toDate: toDateStr,
    poNumber: '',
    invoiceNumber: '',
    addressNumber: 0,
    invoiceStatus: 0,
};

const invoiceReducer: Reducer<InvoiceState, Action> = (state, action): InvoiceState => {
    console.log('bolReducer - purchaseOrderReducer', action);
    let newState = { ...state };

    switch (action.type) {
        case Actions.FILTER_SEARCHSTRING:
            newState = { ...state, searchString: action.payload };
            break;
        case Actions.FILTER_CUSTOMER_SELECT:
            newState = { ...state, customer: Number(action.payload) };
            break;
        case Actions.FILTER_FROMDATE_SELECT:
            newState = { ...state, fromDate: action.payload };
            break;
        case Actions.FILTER_TODATE_SELECT:
            newState = { ...state, toDate: action.payload };
            break;
        case Actions.FILTER_PONUMBER:
            newState = { ...state, poNumber: action.payload };
            break;
        case Actions.FILTER_INVOICESTATUS_SELECT:
            newState = { ...state, invoiceStatus: Number(action.payload) };
            break;
        case Actions.FILTER_ADDRESSNUMBER:
            newState = { ...state, addressNumber: Number(action.payload) };
            break;
        case Actions.FILTER_INVOICE:
            newState = { ...state, invoiceNumber: action.payload };
            break;
        case Actions.FILTER_INVOICE_ACTION_APPLYFILTER:
            newState = {
                ...state,
                searchString: action.payload.searchString,
                fromDate: action.payload.fromDate,
                toDate: action.payload.toDate,
                poNumber: action.payload.poNumber,
                invoiceNumber: action.payload.invoiceNumber,
                invoiceStatus: action.payload.invoiceStatus,
            };
            break;
        case Actions.FILTER_INVOICE_ACTION_RESETFILTER:
            newState = {
                ...state,
                searchString: '',
                fromDate: fromDateStr,
                toDate: toDateStr,
                poNumber: '',
                invoiceNumber: '',
                addressNumber: 0,
                invoiceStatus: 0,
            };
            break;
        case CustomerAction.UPDATE_CUSTOMER_ID:
            newState = {
                ...state,
                searchString: '',
                fromDate: fromDateStr,
                toDate: toDateStr,
                poNumber: '',
                invoiceNumber: '',
                addressNumber: 0,
                invoiceStatus: 0,
            };
            break;
    }

    return newState;
};

export { initialInvoiceState, invoiceReducer };
