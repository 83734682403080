import React, { useCallback, useState } from 'react';
import { Query, QueryResult } from 'react-apollo';
import SearchBar from '../SearchBar';
import { SearchTypeEnum } from '../../types/SearchTypeEnum';
import { ModalForm, Option, CPOption } from './interface';
import { Alert } from 'react-bootstrap';
import { GET_B2C_USER_FILTERED, GET_ROLES, GET_ALL_CUSTOMERSLIST } from '../../graphql/client/queries';
import {
    GetRoles_getRoles as Role,
    GetAllCustomersList_getAllCustomersList as CustomerParent,
    GetAllCustomersList_getAllCustomersList as Customer,
    GetB2CUserFiltered_getB2CUserFiltered as B2CUser,
    GetB2CUserFiltered,
    GetAllCustomersList,
} from '../../types/schemaTypes';
import UserList from './UserList';
import { Error, Loading } from '../_common';
import AddUserModal from './AddEditUserModal/AddEditUserModal';
import './Users.scss';
import 'material-design-icons/iconfont/material-icons.css';
import { useQuery } from '@apollo/react-hooks';

import { useStoreContext } from '../App/StoreProvider';
import { useAuthContext } from '../Auth/AuthProvider';
import { PermissionEnum } from '../../types/PermissionEnum';
import { useMutation } from '@apollo/react-hooks';
import { DELETE_B2C_USER } from '../../graphql/client/mutations';
import toaster from 'toasted-notes';
import ConfirmDispatchModal from './ComfirmModal/DispatchModal';
import ForbiddenError from '../_common/Error/ForbiddenError';
import * as UserListActions from '../../store/actions/userList';

const toasterOptions = { duration: 2500 };
const Messages = {
    delete: 'User deleted successfully',
    userAdded: 'User added successfully',
    userEdited: 'User details saved successfully',
};
const ErrorMessages = {
    delete: 'Failed to delete User',
};
let selectedUser: B2CUser | undefined;

const PAGE_SIZE = 50;

const Users = (): JSX.Element => {
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [modalForm, setModalForm] = useState(ModalForm.EMPTY);
    const [isEndOfResults, setIsEndOfResults] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedCustomerId, setSelectedCustomerId] = useState(0);
    const [tabTitle, setTabTitle] = useState(document.title);
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [showDispatchModal, setDispatchModal] = useState(false);
    document.title = 'SBX OPS Portal';
    const isAdmin = useAuthContext().userHasPermission(PermissionEnum.Admin);
    const isCustomerService = useAuthContext().userHasPermission(PermissionEnum.CustomerService);
    const canEditUsers = isAdmin || isCustomerService;
    const [refetched, setRefetched] = useState(false);
    const [state, dispatch] = useStoreContext();

    const {
        userList: { searchPhrase, customerId },
    } = state;

    const variables = {
        input: {
            searchString: searchPhrase,
            customerId: selectedCustomerId,
        },
    };

    const renderToast = (message, type = 'info') => {
        interface ToastProps {
            onClose?: () => void;
        }
        const Comp = (props: ToastProps) => {
            return (
                <Alert variant={type} onClose={props.onClose}>
                    {message}
                </Alert>
            );
        };

        Comp.displayName = 'toast';
        return Comp;
    };

    const onCompleted = action => {
        toaster.notify(renderToast(Messages[action], 'success'), toasterOptions);
    };

    const onActionError = action => {
        toaster.notify(renderToast(ErrorMessages[action], 'danger'), toasterOptions);
    };

    const checkIfPassword = () => {
        if (isResetPassword) {
            setDispatchModal(true);
        }
    };

    const handleClickHide = (): void => {
        setDispatchModal(false);
        setIsResetPassword(false);
    };

    const handleOnHide = (): void => {
        selectedUser = undefined;
        setErrorMessage('');
        setShowAddUserModal(false);
    };

    const onUserSaved = () => {
        !isResetPassword && onCompleted(selectedUser ? 'userEdited' : 'userAdded');
        selectedUser = undefined;
        setErrorMessage('');
        setShowAddUserModal(false);
        setRefetched(true);
    };

    const [deleteUser] = useMutation(DELETE_B2C_USER, {
        onCompleted: () => {
            onCompleted('delete');
            setRefetched(true);
        },
        onError: () => onActionError('delete'),
        refetchQueries: () => {
            return [
                {
                    query: GET_B2C_USER_FILTERED,
                    variables: { variables },
                },
            ];
        },
    });

    const onDelete = useCallback(async (record: any) => {
        const variables = { input: { userId: record.userId } };
        await deleteUser({ variables });
    }, []);

    const input = {
        search: '',
        customerId: 0,
    };
    const { data: customerParentData } = useQuery(GET_ALL_CUSTOMERSLIST, { variables: { input } });
    const cpOptions: CPOption[] = [];
    if (customerParentData && customerParentData.getAllCustomerList) {
        customerParentData.getAllCustomerList.forEach((element: CustomerParent) => {
            if (element.id !== null) {
                const id = element.id;
                const displayName = element && element.customerName ? element.customerName : '<None>';
                const jdeId = element && element.id ? element.id.toString() : '<None>';
                cpOptions.push({
                    id: id,
                    name: displayName,
                    jdeId: jdeId,
                });
            }
        });
    }

    const { data: rolesData } = useQuery(GET_ROLES);

    const options: Option[] = [];

    if (rolesData && rolesData.getRoles) {
        rolesData.getRoles.forEach((element: Role) => {
            if (element.roleId !== null) {
                const id = element.roleId;
                const displayName = element && element.roleName ? element.roleName : '<None>';

                options.push({
                    id: id,
                    name: displayName,
                });
            }
        });
    }
    document.title = 'Users';

    if (!canEditUsers && !isCustomerService) {
        return <ForbiddenError />;
    }

    const inputCustomerList = {
        search: '',
        customerId: 0,
    };

    const inputVariable = {
        input: inputCustomerList,
    };

    const onSelectCustomer = e => {
        setSelectedCustomerId(Number(e.target.value));
    };

    return (
        <Query query={GET_B2C_USER_FILTERED} variables={variables} fetchPolicy="network-only">
            {({
                error,
                data,
                loading,
                refetch,
                fetchMore,
            }: QueryResult<GetB2CUserFiltered | undefined>): JSX.Element | null => {
                if (error) {
                    return <Error error={error} />;
                }

                const users: B2CUser[] = [];

                if (data && data.getB2CUserFiltered && data.getB2CUserFiltered.length !== 0) {
                    data.getB2CUserFiltered.forEach((element: B2CUser | null): void => {
                        if (element !== null) {
                            users.push(element);
                        }
                    });
                }

                const offset = data && data.getB2CUserFiltered ? data.getB2CUserFiltered.length / PAGE_SIZE : 0;

                if (
                    offset % 1 !== 0 ||
                    (!isEndOfResults && data && data.getB2CUserFiltered && data.getB2CUserFiltered.length < PAGE_SIZE)
                ) {
                    // If offset is not a whole number, the end of the results has been reached. (8/22/19) - BS
                    setIsEndOfResults(true);
                }

                return (
                    <div className="Users-Container">
                        <div className="Users-Top-Container">
                            <div className="Users-SearchBar">
                                <Query
                                    query={GET_ALL_CUSTOMERSLIST}
                                    variables={inputVariable}
                                    fetchPolicy="network-only"
                                >
                                    {({
                                        error,
                                        data,
                                        loading,
                                        refetch,
                                        fetchMore,
                                    }: QueryResult<GetAllCustomersList | undefined>): JSX.Element | null => {
                                        if (error) {
                                            return <Error error={error} />;
                                        } else {
                                            const customerUsers: Customer[] = [];

                                            if (
                                                data &&
                                                data.getAllCustomerList &&
                                                data.getAllCustomerList.length !== 0
                                            ) {
                                                data.getAllCustomerList.forEach((element: Customer | null): void => {
                                                    if (element !== null) {
                                                        customerUsers.push(element);
                                                    }
                                                });
                                            }
                                            return (
                                                <div className="pl-3">
                                                    <select
                                                        className="form-control"
                                                        onChange={e => onSelectCustomer(e)}
                                                        value={selectedCustomerId}
                                                    >
                                                        <option value={0}>-- Select Customer --</option>
                                                        {customerUsers.map((customer: any, index) => (
                                                            <option
                                                                key={customer.id?.toString()}
                                                                value={customer.id?.toString()}
                                                            >
                                                                {customer.customerName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            );
                                        }
                                    }}
                                </Query>
                                <SearchBar type={SearchTypeEnum.User}></SearchBar>
                            </div>
                            <div className="Users-RightOptions">
                                {canEditUsers && (
                                    <div
                                        className="Users-Create-New-User Row"
                                        onClick={() => {
                                            setShowAddUserModal(true);
                                            errorMessage && setErrorMessage('');
                                            setModalForm(ModalForm.ADD_USER);
                                        }}
                                    >
                                        <i className="material-icons Users-Icon">add_circle</i>
                                        <span className="Users-Link">Create New User</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="Users-Bottom-Container">
                            <div className="Users-Bottom-Header-Container">
                                <div className="Users-Header-Title">Name</div>
                                <div className="Users-Header-Title Users-Header-Title-Mid">Email</div>
                                <div className="Users-Header-Title">Role</div>
                                <div className="Users-Header-Title Users-Header-Title-Lg">Customer Parent</div>
                                <div className="Users-Header-Title Users-Header-Title-Mid">Customer Parent JDE ID</div>
                                <div className="Users-Header-Title">Status</div>
                                <div className="Users-Header-Title">Actions</div>
                            </div>
                            {loading && (
                                <div className="SpinnerContainer">
                                    <Loading />
                                </div>
                            )}

                            {users.length > 0 && (
                                <UserList
                                    users={users}
                                    options={options}
                                    cpOptions={cpOptions}
                                    isLoading={loading}
                                    canEditUsers={canEditUsers}
                                    refetch={refetch}
                                    refetched={refetched}
                                    setRefetched={setRefetched}
                                    isEndOfResults={isEndOfResults}
                                    onEditClick={user => {
                                        console.log('on edit user', user);
                                        setShowAddUserModal(true);
                                        errorMessage && setErrorMessage('');
                                        setModalForm(ModalForm.EDIT_USER);
                                        selectedUser = user;
                                    }}
                                    onDelete={onDelete}
                                    onLoadMore={() =>
                                        fetchMore({
                                            variables: {
                                                offset,
                                            },
                                            updateQuery: (prev, { fetchMoreResult }) => {
                                                if (
                                                    prev &&
                                                    fetchMoreResult &&
                                                    prev.getB2CUserFiltered &&
                                                    fetchMoreResult.getB2CUserFiltered
                                                ) {
                                                    return Object.assign({}, prev, {
                                                        getB2CUserFiltered: [
                                                            ...prev.getB2CUserFiltered,
                                                            ...fetchMoreResult.getB2CUserFiltered,
                                                        ],
                                                    });
                                                } else {
                                                    return prev;
                                                }
                                            },
                                        })
                                    }
                                />
                            )}
                            <AddUserModal
                                show={showAddUserModal}
                                modalForm={modalForm}
                                options={options}
                                cpOptions={cpOptions}
                                selectedUser={selectedUser}
                                onClickHide={handleOnHide}
                                onUserSaved={onUserSaved}
                                checkIfPassword={checkIfPassword}
                                setIsResetPassword={setIsResetPassword}
                                errorMessage={errorMessage}
                                setErrorMessage={setErrorMessage}
                                userId={selectedUser?.userId == null ? 0 : selectedUser.userId}
                                inputVariables={variables.input}
                            />
                            <ConfirmDispatchModal
                                isResetPassword={true}
                                show={showDispatchModal}
                                onClickHide={handleClickHide}
                            />
                        </div>
                    </div>
                );
            }}
        </Query>
    );
};

export default Users;
